import React from "react";
import { BillboardHoverPopupProps } from "../index";
import { useAppContext } from "../../../AppContext";
import { pointEquality } from "../../../utils/Point";
import { BitPixels } from "../../../metamask/contracts/BitPixels";

export default function HoverHomeContent(props: BillboardHoverPopupProps) {
  const { modifiablePixelGroups } = useAppContext()
  return <>
    {modifiablePixelGroups.filter(value => value.map(value => BitPixels.pixelIdToPoint(value.id)).contains(props.hovered, pointEquality)).length > 0 ?
      <>
        Click to modify powers of these pixels.
      </>
      :
      <></>
    }
  </>
}
