import { BigNumber, ethers } from "ethers";
import { Bitpixels__factory } from "../../.generated/contracts";
import { Point } from "../../utils/Point";
import { useEffect, useState } from "react";
import { useCachedStateWithDefault } from "../../hooks/UseCachedState";
import { Rect, rectToPoints } from "../../utils/Rect";
import { ID_POWER_MAP } from "../../components/BillboardSelectionPopup";


export interface MapInfo {
  pixels: PixelInfo[][]
}

export interface PixelInfo {
  id: number
  owner: number
  power: number
  maxTimeMs: number
  groupId: string
}

/** @see {isSupply} ts-auto-guard:type-guard */
export type Supply = number

interface StringNumberMap {
  [thingName: string]: number
}

export type Balances = StringNumberMap

let SIZE = 4

export namespace BitPixels {

  export function getContract(signed?: boolean) {
    let provider = new ethers.providers.Web3Provider((window as any).ethereum)
    return Bitpixels__factory.connect(process.env.REACT_APP_BIT_PIXELS_CONTRACT_ADDRESS!!, signed ? provider.getSigner() : provider)
  }

  export async function enter() {
    let transaction = await BitPixels.getContract(true).enterGame({
      value: ethers.utils.parseEther(process.env.REACT_APP_PIXEL_PRICE!).mul(4),
      gasLimit: 1_000_000,
    })
    await transaction.wait()
  }

  export async function setPowers(powers: ID_POWER_MAP) {
    let transaction = await BitPixels.getContract(true).redistributePowers(Object.keys(powers).map(value => {
      return {
        pixelId: value,
        groupId: 0,
        power: powers[value],
        maxTime: 0
      }
    }))
    await transaction.wait()
  }

  export function useMapInfo(): MapInfo {
    const [mapInfo, setMapInfo] = useState<MapInfo>({
        pixels: [
          [
            { id: 1, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 5, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 9, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 13, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
          ],
          [
            { id: 2, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 6, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 10, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 14, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
          ],
          [
            { id: 3, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 7, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 11, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 15, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
          ],
          [
            { id: 4, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 8, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 12, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
            { id: 16, owner: 0, power: 0, maxTimeMs: 0, groupId: "" },
          ]
        ]
      }
    )

    useEffect(() => {
      let cancelled = false

      function get(ownerId: number) {
        getContract().playerPixels(ownerId).then(result => {
          console.log({ ownerId, result })
          for (let resultElement of result) {
            if (cancelled) {
              return
            }
            let id = resultElement.pixelId.toNumber()
            let point = pixelIdToPoint(id)
            setMapInfo(prevState => {
              prevState.pixels[point.x][point.y].power = resultElement.power.toNumber()
              prevState.pixels[point.x][point.y].groupId = resultElement.groupId.toString()
              prevState.pixels[point.x][point.y].maxTimeMs = resultElement.maxTime.toNumber() * 1000
              prevState.pixels[point.x][point.y].owner = ownerId
              return Object.assign({}, prevState)
            })
          }
        })
      }

      function update(args?: any) {
        //   if (args instanceof BigNumber) {
        //     get(args.toNumber())
        //   } else {
        get(1)
        get(2)
        get(3)
        get(4)
        //   }
      }

      update()
      let filter = getContract().filters.PixelChanged()
      getContract().on(filter, update)
      return () => {
        cancelled = true
        getContract().off(filter, update)
      }
    }, [])

    return mapInfo
  }

  export function useSupply(correctChain: boolean) {
    const [supply, setSupply] = useCachedStateWithDefault<Supply>("supplyCache", false, 0)

    useEffect(() => {
      if (!process.env.REACT_APP_BIT_PIXELS_CONTRACT_ADDRESS) {
        //PROD ADDRESS IS NULL
        return
      }
      if (!correctChain) {
        return
      }
      let cancelled = false

      function update() {
        getContract().totalSupply().then(value => {
          if (cancelled) {
            return
          }
          setSupply(value.toNumber())
        })
      }

      update()
      let filter = getContract().filters.Transfer()
      getContract().on(filter, update)
      return () => {
        cancelled = true
        getContract().off(filter, update)
      }
    }, [correctChain, setSupply])

    return supply
  }

  export function useBalance(account: string | null | undefined) {
    const [balance, setBalance] = useCachedStateWithDefault<Balances>("balanceCache", false, {})

    useEffect(() => {
      if (!account) {
        return
      }

      let cancelled = false
      let _acc = account

      function update() {
        getContract().balanceOf(_acc).then(value => {
          if (cancelled) {
            return
          }
          setBalance(prevState => {
            const newValue = value.toNumber()
            if (prevState[_acc] === newValue) {
              return prevState
            }
            const newState = { ...prevState }
            newState[_acc] = value.toNumber()
            return newState
          })
        })
      }

      update()
      let toFilter = getContract().filters.Transfer(undefined, account)
      let fromFilter = getContract().filters.Transfer(account)
      getContract().on(toFilter, update)
      getContract().on(fromFilter, update)
      return () => {
        cancelled = true
        getContract().off(toFilter, update)
        getContract().off(fromFilter, update)
      }
    }, [account, setBalance])

    return account ? balance[account] : 0
  }

  export function usePlayer(account: string | null | undefined, supply: number) {
    const [player, setPlayer] = useCachedStateWithDefault<Balances>("player", false, {})

    useEffect(() => {
      if (!account) {
        return
      }
      if (supply === 0) {
        return
      }

      let cancelled = false
      let _acc = account

      function update() {
        getContract().tokenOfOwnerByIndex(_acc, 0).then(value => {
          if (cancelled) {
            return
          }
          setPlayer(prevState => {
            const newValue = value.toNumber()
            if (prevState[_acc] === newValue) {
              return prevState
            }
            const newState = { ...prevState }
            newState[_acc] = value.toNumber()
            return newState
          })
        })
      }

      update()
      let toFilter = getContract().filters.Transfer(undefined, account)
      let fromFilter = getContract().filters.Transfer(account)
      getContract().on(toFilter, update)
      getContract().on(fromFilter, update)
      return () => {
        cancelled = true
        getContract().off(toFilter, update)
        getContract().off(fromFilter, update)
      }
    }, [account, setPlayer, supply])
    return account ? player[account] : undefined
  }


  function arrayFlatten<T>(pixels: T[][]): T[] {
    return pixels.reduce((previousValue, currentValue) => [...previousValue, ...currentValue], [])
  }

  export function useModifiablePowerGroups(mapInfo: MapInfo, player?: number): PixelInfo[][] {
    const [groups, setGroups] = useState<PixelInfo[][]>([])

    useEffect(() => {
      if (player === undefined) {
        return
      }

      let timeout: any = {}

      function update() {
        let r = arrayFlatten(mapInfo.pixels)
          .filter(value => value.owner === player && value.maxTimeMs > Date.now())
        let nextTime = r.reduce((previousValue, currentValue) => Math.min(previousValue, currentValue.maxTimeMs), r[0]?.maxTimeMs ?? 0)
        let g = Array.from(r.groupBy(value => value.groupId).values())
        setGroups(g)
        timeout.t = setTimeout(update, nextTime - Date.now())
      }

      update()

      return () => {
        if (timeout.t) {
          clearTimeout(timeout.t)
        }
      }
    }, [mapInfo, player])

    return groups
  }

  export async function attack(pixels: PixelInfo[], player?: number) {
    let from = pixels.filter(value => value.owner === player)[0]
    let to = pixels.filter(value => value.owner !== player)[0]
    let transaction = await BitPixels.getContract(true).declareWar([from.id], [to.id], { gasLimit: 1000000 })
    await transaction.wait()
  }

  export function pointToTokenId(point: Point): number {
    return point.x + point.y * SIZE + 1
  }

  export function pixelIdToPoint(id: number): Point {
    let idd = id - 1
    return { x: idd % SIZE, y: Math.floor(idd / SIZE) }
  }

  export function findGroupFromPixelInfo(mapInfo: MapInfo, modifiablePixelGroups: PixelInfo[][], r: Point): PixelInfo[] {
    return modifiablePixelGroups.filter(value => value.contains(mapInfo.pixels[r!.x][r!.y]))?.[0]
  }


  export function isRectIsAGroup(selected: Rect, mapInfo: MapInfo, modifiablePixelGroups: PixelInfo[][]) {
    let selectedPixels = rectToPoints(selected).map(value => mapInfo.pixels[value.x][value.y])
    let selectedGroup = modifiablePixelGroups.filter(value => value.containsAll(selectedPixels))
    return selectedGroup && selectedGroup.length === selectedPixels.length;
  }

  export function convertPixelInfosToRect(pixelInfos: PixelInfo[]): Rect {
    let points = pixelInfos.map(value => pixelIdToPoint(value.id))
    let xs = points.map(value => value.x)
    let ys = points.map(value => value.y)
    let minX = Math.min(...xs)
    let minY = Math.min(...ys)
    let maxX = Math.max(...xs)
    let maxY = Math.max(...ys)
    return {
      x: minX,
      y: minY,
      width: maxX - minX + 1,
      height: maxY - minY + 1,
    }
  }
}
