export type Equality<T> = (e1: T, e2: T) => boolean
export {};

declare global {
  export interface Array<T> {
    contains(element: T, equality?: Equality<T>): boolean

    containsAll(elements: T[], equality?: Equality<T>): boolean

    containsAny(elements: T[], equality?: Equality<T>): boolean

    equals(elements: T[], equality?: Equality<T>): boolean

    groupBy<K>(grouper: (t: T) => K): Map<K, Array<T>>
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.contains = function <T>(element: T, equality?: Equality<T>): boolean {
  return this.filter(value => {
    if (equality) {
      return equality(value, element)
    } else {
      return value === element
    }
  }).length > 0
}

// eslint-disable-next-line no-extend-native
Array.prototype.containsAll = function <T>(elements: T[], equality?: Equality<T>): boolean {
  for (let element of elements) {
    if (!this.contains(element, equality)) {
      return false
    }
  }
  return true
}

// eslint-disable-next-line no-extend-native
Array.prototype.containsAny = function <T>(elements: T[], equality?: Equality<T>): boolean {
  for (let element of elements) {
    if (this.contains(element, equality)) {
      return true
    }
  }
  return false
}

// eslint-disable-next-line no-extend-native
Array.prototype.equals = function <T>(elements: T[], equality?: Equality<T>): boolean {
  if (this === elements) {
    return true
  }
  if (this.length !== elements.length) {
    return false
  }
  for (let i = 0; i < this.length; i++) {
    const equals = equality ? equality(this[i], elements[i]) : this[i] === elements[i]
    if (!equals) {
      return false
    }
  }
  return true
}

// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function <T, K>(grouper: (t: T) => K): Map<K, Array<T>> {
  const map = new Map<K, Array<T>>()
  this.forEach(value => {
    const key = grouper(value)
    const list = map.get(key)
    if (list) {
      list.push(value)
    } else {
      map.set(key, [value])
    }
  })
  return map
}
