import React, { useContext, useMemo, useState } from 'react';
import './App.css';
import { Metamask } from "./metamask/Metamask";
import { BitPixels, MapInfo, PixelInfo } from "./metamask/contracts/BitPixels";

interface AppContextInterface {
  correctChain?: boolean
  account?: string | null
  player?: number
  mapInfo: MapInfo
  modifiablePixelGroups: PixelInfo[][]
  supply: number
  balance: number
  refresh: () => void
}

const AppContext = React.createContext<AppContextInterface>({
  mapInfo: { pixels: [] },
  player: undefined,
  modifiablePixelGroups: [],
  supply: 0,
  balance: 0,
  refresh: () => {
  },
});

export function AppContextProvider(props: { children: React.ReactNode }) {
  const [refreshDate, setRefreshDate] = useState(Date.now)
  const chainId = Metamask.useChainId()
  const correctChain = chainId?.toUpperCase() === Metamask.CHAIN_PARAMS.chainId.toUpperCase()
  const account = Metamask.useAccount(correctChain)
  const supply = BitPixels.useSupply(correctChain)
  const balance = BitPixels.useBalance(account)
  const player = BitPixels.usePlayer(account, supply)
  const mapInfo = BitPixels.useMapInfo()
  const modifiablePixelGroups = BitPixels.useModifiablePowerGroups(mapInfo, player)

  const state: AppContextInterface = useMemo(() => {
    return {
      correctChain: correctChain,
      account: account,
      mapInfo: mapInfo,
      player: player,
      modifiablePixelGroups: modifiablePixelGroups,
      supply: supply,
      balance: balance,
      refresh: () => {
        setRefreshDate(Date.now)
      },
    }
  }, [correctChain, account, mapInfo, player, modifiablePixelGroups, supply, balance])

  return (
    <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext)
}
