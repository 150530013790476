export interface CustomTimeout {
  runNow: () => void
  clear: () => void
}

export function setTimeoutCustom(handler: Function, timeout?: number, ...args: any[]): CustomTimeout {
  let runStarted = false
  let cleared = false
  const handlerWrapper = () => {
    runStarted = true
    handler()
  }
  let r = setTimeout(handlerWrapper, timeout, args)

  return {
    runNow: () => {
      if (cleared || runStarted) {
        return
      }
      clearTimeout(r);
      handlerWrapper()
    },
    clear: () => {
      cleared = true
      clearTimeout(r)
    }
  }
}
