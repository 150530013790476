import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Location } from "history";

export function useLocation(): Location<unknown> {

  const history = useHistory()
  const [path, setPath] = useState(history.location)

  useEffect(() => {
    let l = history.listen(location => {
      setPath(location)
    })
    return () => {
      l()
    }
  }, [history])

  return path;
}
