export {};
declare global {
  export interface File {
    toBase64(): Promise<string>;
  }
}

File.prototype.toBase64 = function (): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(this);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}
