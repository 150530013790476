import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { AppCache } from "../utils/AppCache";

export function useCachedState<T>(cacheKey: string, isCachePersistent: boolean, validityCheck?: AppCache.ValidityCheck<T>): readonly [T | undefined, React.Dispatch<React.SetStateAction<T>>] {
  const [getValue, setValue] = useState<T | undefined>(AppCache.get(cacheKey, validityCheck))
  // noinspection DuplicatedCode
  const setCallback: Dispatch<SetStateAction<T>> = useCallback(value => {
    if (typeof value === 'function') {
      setValue(prev => {
        let newValue = (value as any)(prev)
        AppCache.put(cacheKey, newValue, isCachePersistent)
        return newValue
      })
    } else {
      AppCache.put(cacheKey, value, isCachePersistent)
      setValue(value)
    }
  }, [setValue, cacheKey, isCachePersistent])
  return [getValue, setCallback] as const
}

export function useCachedStateWithDefault<T>(cacheKey: string, isCachePersistent: boolean, defaultValue: T, validityCheck?: AppCache.ValidityCheck<T>): readonly [T, React.Dispatch<React.SetStateAction<T>>] {
  const [getValue, setValue] = useState<T>(AppCache.getWithDefault(cacheKey, defaultValue, validityCheck))
  // noinspection DuplicatedCode
  const setCallback: Dispatch<SetStateAction<T>> = useCallback(value => {
    if (typeof value === 'function') {
      setValue(prev => {
        let newValue = (value as any)(prev)
        AppCache.put(cacheKey, newValue, isCachePersistent)
        return newValue
      })
    } else {
      AppCache.put(cacheKey, value, isCachePersistent)
      setValue(value)
    }
  }, [setValue, cacheKey, isCachePersistent])
  return [getValue, setCallback] as const
}
