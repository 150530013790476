import styles from './style.module.css'
import { Link } from "react-router-dom";
import { useLocation } from "../../hooks/UseLocation";

export default function NavigationItem(props: { text: string, target: string }) {
  const path = useLocation()

  return (
    <Link to={props.target}
          className={`${styles.root} ${path.pathname === props.target ? styles.selected : ''}`}>
      {props.text}
    </Link>
  );
}
