import styles from './style.module.css'
import React, {
  HTMLAttributes,
  MouseEventHandler,
  TouchEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  WheelEventHandler
} from "react";
import { Color } from "../../utils/Colors";
import BillboardSelectionPopup, { BillboardSelectionPopupRef } from "../BillboardSelectionPopup";
import { useReferredElement } from "../../hooks/UseReferredElement";
import useElementSize from "../../hooks/UseElementSize";
import { Point, pointEquality } from "../../utils/Point";
import BillboardHoverPopup, { BillboardHoverPopupRef } from "../BillboardHoverPopup";
import Button from "../Button";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { ReactComponent as SelectIcon } from 'images/select_cursor_icon.svg'
import { ReactComponent as GrabIcon } from 'images/grab_cursor_icon.svg'
import { CustomTimeout, setTimeoutCustom } from "../../utils/SetTimeoutCustom";
import { BitPixels, MapInfo, PixelInfo } from "../../metamask/contracts/BitPixels";
import { msToRemainingTime, omittedCopy } from "../../utils/GeneralUtil";
import { Rect } from "../../utils/Rect";
import BluePlayerIcon from "../../images/blue.png"
import OrangePlayerIcon from "../../images/orange.png"
import RedPlayerIcon from "../../images/red.png"
import GreenPlayerIcon from "../../images/green.png"
import GrayPlayerIcon from "../../images/gray.png"

const PLAYER_COLOR = "rgba(25,229,88,0.6)"
const PLAYER_COOLDOWN_COLOR = "rgba(25,229,88,0.3)"
const ENEMY_COOLDOWN = "rgba(124,124,124,0.4)"
const ENEMY = "rgba(255,255,255,0)"


const SIZE = 4

const ZOOM_STEP = 0.05
const MAX_ZOOM = 10
const MIN_ZOOM = 1

export default function Billboard(props: { mapInfo: MapInfo, player?: number, modifiablePixelGroups: PixelInfo[][] } & HTMLAttributes<HTMLDivElement>) {
  const [canvas, canvasRef] = useReferredElement<HTMLCanvasElement>()
  const [popupDiv, popupRef] = useReferredElement<BillboardSelectionPopupRef>()
  const [hoverPopup, hoverPopupRef] = useReferredElement<BillboardHoverPopupRef>()
  const canvasSize = useElementSize(canvas)
  const [selectionStart, setSelectionStart] = useState<Point>()
  const [selectionEnd, setSelectionEnd] = useState<Point>()
  const [mouseDrag, setMouseDrag] = useState(false)
  const [interaction, setInteraction] = useState<'mouse' | 'touch'>("mouse")
  const [popupLocation, setPopupLocation] = useState<{ top: string, left: string }>({ top: '0%', left: '0%' })
  const [mouseLocationPercentageOnCanvas, setMouseLocationPercentageOnCanvas] = useState<Point>()
  const [hoverPopupLocation, setHoverPopupLocation] = useState<{ top: string, left: string }>({ top: '0%', left: '0%' })
  const [zoomLevel, setZoomLevel] = useState(1.0)
  const [zoomLocation, setZoomLocation] = useState<Point>({ x: 0.5, y: 0.5 })
  const [selectionMode, setSelectionMode] = useState(true)
  const [dragStartLocationOnCanvas, setDragStartLocationOnCanvas] = useState<Point>()
  const [dragEndLocationOnCanvas, setDragEndLocationOnCanvas] = useState<Point>()
  const [pinchStartDistance, setPinchStartDistance] = useState<number>()
  const defaultScrollEnabled = useRef(true)
  const clickTimeOut = useRef<CustomTimeout>()

  let COLORS: { color: string, image: HTMLImageElement }[] = useMemo(() => {

    return [
      {
        color: "#a8a8a8",
        image: (() => {
          let image = new Image()
          image.src = GrayPlayerIcon
          return image
        })()
      }
      ,
      {
        color: "#e51919",
        image: (() => {
          let image = new Image()
          image.src = RedPlayerIcon
          return image
        })()
      },
      {
        color: "#1940e5",
        image: (() => {
          let image = new Image()
          image.src = BluePlayerIcon
          return image
        })()
      },
      {
        color: "#286700",
        image: (() => {
          let image = new Image()
          image.src = GreenPlayerIcon
          return image
        })()
      },
      {
        color: "#ff9d00",
        image: (() => {
          let image = new Image()
          image.src = OrangePlayerIcon
          return image
        })()
      }]


  }, [])

  useEffect(() => {
    function preventDefault(e: any) {
      if (!defaultScrollEnabled.current) {
        if (e.preventDefault) {
          e.preventDefault()
        }
        e.returnValue = false
      }
    }

    document.addEventListener('wheel', preventDefault, {
      passive: false,
    })
    document.addEventListener('touchstart', preventDefault, {
      passive: false,
    })
    document.addEventListener('touchmove', preventDefault, {
      passive: false,
    })

    return () => {
      document.removeEventListener("wheel", preventDefault, false)
      document.removeEventListener("touchstart", preventDefault, false)
      document.removeEventListener("touchstart", preventDefault, false)
    }
  }, [])

  let CANVAS_WIDTH_DRAW = canvasSize.width * window.devicePixelRatio;

  //region Canvas locations
  const getLocationPercentageOnCanvasFromEvent = useCallback((eventLocation: { clientX: number, clientY: number }) => {
    if (!canvas) {
      return { x: 0, y: 0 }
    }
    const x = (eventLocation.clientX - canvas.getBoundingClientRect().left) / canvas.getBoundingClientRect().width
    const y = (eventLocation.clientY - canvas.getBoundingClientRect().top) / canvas.getBoundingClientRect().height
    return { x, y }
  }, [canvas])

  const getLocationPercentageOnBillboardFromPercentageOnCanvas = useCallback((percentageOnCanvas: Point) => {
    let x = zoomLocation.x - (0.5 / zoomLevel) + percentageOnCanvas.x / zoomLevel
    let y = zoomLocation.y - (0.5 / zoomLevel) + percentageOnCanvas.y / zoomLevel
    return { x, y }
  }, [zoomLevel, zoomLocation])

  const getLocationPercentageOnBillboardFromEvent = useCallback((eventLocation: { clientX: number, clientY: number }) => {
    let percentageOnCanvas = getLocationPercentageOnCanvasFromEvent(eventLocation)
    return getLocationPercentageOnBillboardFromPercentageOnCanvas(percentageOnCanvas)
  }, [getLocationPercentageOnCanvasFromEvent, getLocationPercentageOnBillboardFromPercentageOnCanvas])

  const getPointFromPercentageOnCanvas = useCallback((percentageOnCanvas: Point) => {
    let percentageOnAllBillboard = getLocationPercentageOnBillboardFromPercentageOnCanvas(percentageOnCanvas)
    const x = Math.floor(percentageOnAllBillboard.x * SIZE)
    const y = Math.floor(percentageOnAllBillboard.y * SIZE)
    return { x, y }
  }, [getLocationPercentageOnBillboardFromPercentageOnCanvas])

  const getPointFromEvent = useCallback((eventLocation: { clientX: number, clientY: number }) => {
    const percentages = getLocationPercentageOnCanvasFromEvent(eventLocation)
    return getPointFromPercentageOnCanvas(percentages)
  }, [getPointFromPercentageOnCanvas, getLocationPercentageOnCanvasFromEvent])

  const getLocationPercentageOnCanvasFromPoint = useCallback((point: Point) => {
    let zoomedPercentageX = point.x / SIZE
    let zoomedPercentageY = point.y / SIZE
    let x = (zoomedPercentageX - zoomLocation.x + (0.5 / zoomLevel)) * zoomLevel
    let y = (zoomedPercentageY - zoomLocation.y + (0.5 / zoomLevel)) * zoomLevel
    return { x, y }
  }, [zoomLocation, zoomLevel])

  function zoomLocationIf(locationOnBillboard: Point, locationOnCanvas: Point, zoomLevel: number) {
    let x = locationOnBillboard.x - (locationOnCanvas.x / zoomLevel) + (0.5 / zoomLevel)
    let y = locationOnBillboard.y - (locationOnCanvas.y / zoomLevel) + (0.5 / zoomLevel)
    return {
      x: Math.max(0.5 / zoomLevel, Math.min(1 - 0.5 / zoomLevel, x)),
      y: Math.max(0.5 / zoomLevel, Math.min(1 - 0.5 / zoomLevel, y)),
    }
  }

  //endregion

  const selected = useMemo(() => {
    let r: Rect | undefined = undefined;
    if (selectionStart && selectionEnd) {
      const minX = Math.min(selectionStart.x, selectionEnd.x)
      const maxX = Math.max(selectionStart.x, selectionEnd.x)
      const minY = Math.min(selectionStart.y, selectionEnd.y)
      const maxY = Math.max(selectionStart.y, selectionEnd.y)
      r = {
        x: minX,
        y: minY,
        width: maxX - minX + 1,
        height: maxY - minY + 1,
      };
    } else if (selectionStart) {
      r = {
        x: selectionStart.x,
        y: selectionStart.y,
        width: 1,
        height: 1
      }
    }
    if (r !== undefined && r.width === 1 && r.height === 1) {
      let group = BitPixels.findGroupFromPixelInfo(props.mapInfo, props.modifiablePixelGroups, r!)
      if (group) {
        r = BitPixels.convertPixelInfosToRect(group)
      }
    }
    return r
  }, [selectionStart, selectionEnd, props.mapInfo, props.modifiablePixelGroups])

  const hoverPoint = useMemo(() => {
    if (mouseLocationPercentageOnCanvas) {
      return getPointFromPercentageOnCanvas(mouseLocationPercentageOnCanvas)
    } else {
      return undefined
    }
  }, [mouseLocationPercentageOnCanvas, getPointFromPercentageOnCanvas])

  useLayoutEffect(() => {
    const div = popupDiv?.root
    if ((!selectionEnd && !selectionStart) || !canvas || !div) {
      setPopupLocation({ left: '0%', top: '0%' })
      return
    }
    const locationOnCanvas = getLocationPercentageOnCanvasFromPoint(selectionEnd ?? selectionStart!)
    const requestedLocation = {
      top: locationOnCanvas.y,
      left: locationOnCanvas.x
    }
    const locationWithoutOverflow = findLocationWithoutOverflow(canvas, div, requestedLocation)
    setPopupLocation(locationWithoutOverflow)
  }, [selectionEnd, selectionStart, setPopupLocation, popupDiv, canvas, getLocationPercentageOnCanvasFromPoint])

  useLayoutEffect(() => {
    const div = hoverPopup?.root
    if (!mouseLocationPercentageOnCanvas || !canvas || !div) {
      setHoverPopupLocation({ left: '0%', top: '0%' })
      return
    }
    const requestedLocation = {
      top: mouseLocationPercentageOnCanvas.y,
      left: mouseLocationPercentageOnCanvas.x
    }
    const locationWithoutOverflow = findLocationWithoutOverflow(canvas, div, requestedLocation)
    setHoverPopupLocation(locationWithoutOverflow)
  }, [hoverPopup, mouseLocationPercentageOnCanvas, canvas])

  useEffect(() => {
    if (!dragStartLocationOnCanvas || !dragEndLocationOnCanvas) {
      return;
    }
    let diff = {
      x: dragStartLocationOnCanvas!!.x - dragEndLocationOnCanvas.x,
      y: dragStartLocationOnCanvas!!.y - dragEndLocationOnCanvas.y
    }
    setZoomLocation(prevState => {
      const newState = {
        x: Math.max(0.5 / zoomLevel, Math.min(1 - 0.5 / zoomLevel, prevState.x + diff.x / zoomLevel)),
        y: Math.max(0.5 / zoomLevel, Math.min(1 - 0.5 / zoomLevel, prevState.y + diff.y / zoomLevel)),
      }
      if (pointEquality(prevState, newState)) {
        return prevState
      } else {
        return newState
      }
    })
    setDragStartLocationOnCanvas(dragEndLocationOnCanvas)
    return;
  }, [dragStartLocationOnCanvas, dragEndLocationOnCanvas, zoomLevel])

  //region Mouse Callbacks
  function onMouseUpLeaveShared() {
    setInteraction("mouse")
    setMouseDrag(false)
    setMouseLocationPercentageOnCanvas(undefined)
    setDragStartLocationOnCanvas(undefined)
    setDragEndLocationOnCanvas(undefined)
  }

  const onMouseUpCallback: MouseEventHandler<HTMLCanvasElement> = (_) => {
    onMouseUpLeaveShared()
  }

  const onMouseLeaveCallback: MouseEventHandler<HTMLCanvasElement> = (_) => {
    onMouseUpLeaveShared()
    defaultScrollEnabled.current = true
  }

  const onMouseDownCallback: MouseEventHandler<HTMLCanvasElement> = (e) => {
    setInteraction("mouse")
    if (!selectionMode) {
      setDragStartLocationOnCanvas(getLocationPercentageOnCanvasFromEvent(e))
      return
    }
    const point = getPointFromEvent(e)
    setSelectionStart(point)
    setSelectionEnd(undefined)
    setMouseDrag(true)
  }

  const onMouseMoveCallback: MouseEventHandler<HTMLCanvasElement> = (e) => {
    setInteraction("mouse")
    if (!selectionMode && dragStartLocationOnCanvas) {
      setDragEndLocationOnCanvas(getLocationPercentageOnCanvasFromEvent(e))
      return;
    }
    if (!mouseDrag) {
      setMouseLocationPercentageOnCanvas(getLocationPercentageOnCanvasFromEvent(e))
      return
    }
    let point = getPointFromEvent(e)
    setSelectionEnd(point)
  }

  const onMouseEnterCallback: MouseEventHandler<HTMLCanvasElement> = (_) => {
    defaultScrollEnabled.current = false
  }

  const onWheelCallback: WheelEventHandler<HTMLCanvasElement> = (e) => {
    const percentageOnCanvas = getLocationPercentageOnCanvasFromEvent(e)
    const percentageOnBillboard = getLocationPercentageOnBillboardFromEvent(e)
    updateZoomLevelWithOrigin(Math.sign(e.deltaY) * -1, percentageOnCanvas, percentageOnBillboard)
  }

  //endregion

  function updateZoomLevelWithOrigin(sign: number, pointOnCanvas: Point, pointOnBillboard: Point) {
    setZoomLevel(prevState => {
      let nz = Math.min(MAX_ZOOM, Math.max(MIN_ZOOM, prevState + sign * ZOOM_STEP))
      let newLocation = zoomLocationIf(pointOnBillboard, pointOnCanvas, nz)
      setZoomLocation(prevState => {
        if (pointEquality(prevState, newLocation)) {
          return prevState
        } else {
          return newLocation
        }
      })
      return nz
    })
  }

  //region Touch Callbacks
  const onTouchStartCallback: TouchEventHandler<HTMLCanvasElement> = (e: React.TouchEvent<HTMLCanvasElement>) => {
    setInteraction("touch")
    defaultScrollEnabled.current = false
    if (e.touches.length === 2) {
      const touch1Location = getLocationPercentageOnCanvasFromEvent(e.touches[0])
      const touch2Location = getLocationPercentageOnCanvasFromEvent(e.touches[1])
      setDragStartLocationOnCanvas(average(touch1Location, touch2Location))

      setPinchStartDistance(distance(e.touches[0], e.touches[1]))
      clickTimeOut.current?.clear()
    } else {
      setDragStartLocationOnCanvas(undefined)
      setPinchStartDistance(undefined)
      let point = getPointFromEvent(e.touches[0])
      clickTimeOut.current = setTimeoutCustom(() => {
        setSelectionStart(point)
        setSelectionEnd(undefined)
      }, 20)
    }
  }

  const onTouchMoveCallback: TouchEventHandler<HTMLCanvasElement> = (e: React.TouchEvent<HTMLCanvasElement>) => {
    setInteraction("touch")
    if (e.touches.length === 2) {
      const touch1Location = getLocationPercentageOnCanvasFromEvent(e.touches[0])
      const touch2Location = getLocationPercentageOnCanvasFromEvent(e.touches[1])
      setDragEndLocationOnCanvas(average(touch1Location, touch2Location))
      const endDistance = distance(e.touches[0], e.touches[1])
      if (pinchStartDistance) {
        setZoomLevel(Math.min(MAX_ZOOM, Math.max(MIN_ZOOM, zoomLevel * endDistance / pinchStartDistance)))
        setPinchStartDistance(endDistance)
      }
    } else {
      clickTimeOut.current?.runNow()
      setDragEndLocationOnCanvas(undefined)
      let point = getPointFromEvent(e.touches[0])
      setSelectionEnd(point)
    }
  }

  const onTouchEndCallback: TouchEventHandler<HTMLCanvasElement> = (e: React.TouchEvent<HTMLCanvasElement>) => {
    setInteraction("touch")
    e.preventDefault()
    defaultScrollEnabled.current = true
    clickTimeOut.current?.runNow()
    setDragStartLocationOnCanvas(undefined)
    setDragEndLocationOnCanvas(undefined)
  }
  //endregion

  useEffect(() => {
      let animationNumber: number | undefined

      function draw(_: number) {
        const ctx = canvas?.getContext('2d')
        if (ctx == null) {
          return
        }
        let ZOOMED_CANVAS_WIDTH = CANVAS_WIDTH_DRAW * zoomLevel
        let PIXEL = (ZOOMED_CANVAS_WIDTH / SIZE)
        let LINE_WIDTH = PIXEL / 30
        let dx = CANVAS_WIDTH_DRAW * 0.5 - ZOOMED_CANVAS_WIDTH * zoomLocation.x
        let dy = CANVAS_WIDTH_DRAW * 0.5 - ZOOMED_CANVAS_WIDTH * zoomLocation.y
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        for (let i = 0; i < SIZE + 1; i++) {
          ctx.beginPath()
          ctx.moveTo(i * PIXEL + dx + (i === SIZE ? -LINE_WIDTH : 0), dy)
          ctx.lineTo(i * PIXEL + dx + (i === SIZE ? -LINE_WIDTH : 0), SIZE * PIXEL + dy)
          ctx.lineWidth = LINE_WIDTH
          ctx.strokeStyle = Color.line
          ctx.stroke()
        }
        for (let i = 0; i < SIZE + 1; i++) {
          ctx.beginPath()
          ctx.moveTo(dx, i * PIXEL + dy + (i === SIZE ? -LINE_WIDTH : 0))
          ctx.lineTo(SIZE * PIXEL + dx, i * PIXEL + dy + (i === SIZE ? -LINE_WIDTH : 0))
          ctx.lineWidth = LINE_WIDTH
          ctx.strokeStyle = Color.line
          ctx.stroke()
        }
        for (let x = 0; x < props.mapInfo.pixels.length; x++) {
          for (let y = 0; y < props.mapInfo.pixels[x].length; y++) {
            const pixelInfo = props.mapInfo.pixels[x][y]

            let background = "#FFFFFF"
            if (pixelInfo.owner === props.player) {
              if (pixelInfo.maxTimeMs > Date.now()) {
                background = PLAYER_COOLDOWN_COLOR
              } else {
                background = PLAYER_COLOR
              }
            } else {
              if (pixelInfo.maxTimeMs > Date.now()) {
                background = ENEMY_COOLDOWN
              } else {
                background = ENEMY
              }
            }

            ctx.beginPath()
            ctx.fillStyle = background
            ctx.rect(x * PIXEL + dx, y * PIXEL + dy, PIXEL, PIXEL)
            ctx.fill()

            let power = "?"
            if (pixelInfo.maxTimeMs < Date.now() || pixelInfo.owner === props.player) {
              power = pixelInfo.power.toString()
            }

            ctx.fillStyle = COLORS[pixelInfo.owner].color;
            ctx.font = `${PIXEL / 3}px Arial`
            ctx.textAlign = "center"
            ctx.textBaseline = "middle"
            ctx.fillText(power, x * PIXEL + dx + (PIXEL / 2) + PIXEL / 8, y * PIXEL + dy + (PIXEL / 2));

            ctx.drawImage(COLORS[pixelInfo.owner].image, x * PIXEL + dx + (PIXEL / 2) - PIXEL / 3, y * PIXEL + dy + (PIXEL / 2) - PIXEL / 7,  PIXEL / 4, PIXEL / 4)

            if (pixelInfo.maxTimeMs > Date.now()) {
              ctx.fillStyle = "black"
              ctx.font = `${PIXEL / 5}px Arial`
              ctx.textAlign = "right"
              ctx.textBaseline = "top"
              ctx.fillText(msToRemainingTime(pixelInfo.maxTimeMs), x * PIXEL + dx + (PIXEL), y * PIXEL + dy);
            }
          }
        }

        props.modifiablePixelGroups.forEach((pixelInfos) => {
          let pixels = pixelInfos.map(value => BitPixels.pixelIdToPoint(value.id))
          for (let pixel of pixels) {
            if (!pixels.contains({ x: pixel.x - 1, y: pixel.y }, pointEquality)) {
              ctx.beginPath()
              ctx.moveTo(pixel.x * PIXEL + dx, pixel.y * PIXEL + dy)
              ctx.lineTo(pixel.x * PIXEL + dx, (pixel.y + 1) * PIXEL + dy)
              ctx.lineWidth = LINE_WIDTH * 1.5
              ctx.strokeStyle = Color.line
              ctx.stroke()
            }
            if (!pixels.contains({ x: pixel.x + 1, y: pixel.y }, pointEquality)) {
              ctx.beginPath()
              ctx.moveTo((pixel.x + 1) * PIXEL + dx, pixel.y * PIXEL + dy)
              ctx.lineTo((pixel.x + 1) * PIXEL + dx, (pixel.y + 1) * PIXEL + dy)
              ctx.lineWidth = LINE_WIDTH * 1.5
              ctx.strokeStyle = Color.line
              ctx.stroke()
            }
            if (!pixels.contains({ x: pixel.x, y: pixel.y - 1 }, pointEquality)) {
              ctx.beginPath()
              ctx.moveTo(pixel.x * PIXEL + dx, pixel.y * PIXEL + dy)
              ctx.lineTo((pixel.x + 1) * PIXEL + dx, pixel.y * PIXEL + dy)
              ctx.lineWidth = LINE_WIDTH * 1.5
              ctx.strokeStyle = Color.line
              ctx.stroke()
            }
            if (!pixels.contains({ x: pixel.x, y: pixel.y + 1 }, pointEquality)) {
              ctx.beginPath()
              ctx.moveTo((pixel.x) * PIXEL + dx, (pixel.y + 1) * PIXEL + dy)
              ctx.lineTo((pixel.x + 1) * PIXEL + dx, (pixel.y + 1) * PIXEL + dy)
              ctx.lineWidth = LINE_WIDTH * 1.5
              ctx.strokeStyle = Color.line
              ctx.stroke()
            }
          }
        })
        if (selected) {
          ctx.beginPath()
          ctx.fillStyle = Color.addAlpha(Color.mainTint, 0.5);
          ctx.rect(selected.x * PIXEL + dx, selected.y * PIXEL + dy, selected.width * PIXEL, selected.height * PIXEL)
          ctx.fill()
        }
        animationNumber = window.requestAnimationFrame(draw)
      }

      animationNumber = window.requestAnimationFrame(draw)

      return () => {
        if (animationNumber) {
          window.cancelAnimationFrame(animationNumber)
        }
      }
    }

    ,
    [selected, canvas, CANVAS_WIDTH_DRAW, zoomLevel, zoomLocation, props.mapInfo.pixels, props.player, props.modifiablePixelGroups]
  )

  const showHoverOnBelow = interaction === "touch"
  return (
    <div {...omittedCopy(props, ["modifiablePixelGroups", "mapInfo"])}
         className={`${props.className ?? ''} ${styles.root}`}>
      <div className={styles.zoom_div}>
        <Button onClick={_ => {
          setSelectionMode(prevState => !prevState);
        }}>
          {selectionMode ? <SelectIcon /> : <GrabIcon />}
        </Button>

        <Button onMouseHold={() => {
          updateZoomLevelWithOrigin(1, { x: 0.5, y: 0.5 }, zoomLocation)
        }}>
          <ZoomInIcon />
        </Button>

        <Button onMouseHold={() => {
          updateZoomLevelWithOrigin(-1, { x: 0.5, y: 0.5 }, zoomLocation)
        }}>
          <ZoomOutIcon />
        </Button>
      </div>
      {
        selected !== undefined && !showHoverOnBelow ?
          <BillboardSelectionPopup
            className={"noselect"}
            ref={popupRef}
            style={interaction === 'mouse' ?
              {
                pointerEvents: mouseDrag ? "none" : "unset",
                position: "absolute",
                top: popupLocation.top,
                left: popupLocation.left,
                marginTop: 'unset'
              }
              :
              {
                marginTop: '10px'
              }
            }
            selection_start={selectionStart!}
            selected={selected}
            onClose={() => {
              setSelectionStart(undefined)
              setSelectionEnd(undefined)
            }} />
          :
          <></>
      }
      {
        (interaction === "mouse" && hoverPoint && !mouseDrag && selected === undefined && dragStartLocationOnCanvas === undefined) || showHoverOnBelow ?
          <BillboardHoverPopup
            ref={hoverPopupRef}
            style={showHoverOnBelow ? {
              marginTop: '10px'
            } : {
              pointerEvents: "none",
              position: "absolute",
              top: hoverPopupLocation.top,
              left: hoverPopupLocation.left,
              marginTop: 'unset'
            }}
            hovered={showHoverOnBelow ? selected! : hoverPoint!}
            showClose={showHoverOnBelow}
            onClose={showHoverOnBelow ? () => {
              setSelectionStart(undefined)
              setSelectionEnd(undefined)
            } : undefined}
          />
          :
          <></>
      }
      <canvas className={styles.canvas}
              width={CANVAS_WIDTH_DRAW}
              height={CANVAS_WIDTH_DRAW}
              ref={canvasRef}
              style={{
                cursor: selectionMode ? "default" : dragStartLocationOnCanvas ? "grabbing" : "grab"
              }}
              onMouseDown={onMouseDownCallback}
              onMouseUp={onMouseUpCallback}
              onMouseLeave={onMouseLeaveCallback}
              onMouseMove={onMouseMoveCallback}
              onMouseEnter={onMouseEnterCallback}
              onWheel={onWheelCallback}
              onTouchStart={onTouchStartCallback}
              onTouchMove={onTouchMoveCallback}
              onTouchEnd={onTouchEndCallback} />
    </div>
  );
}

function findLocationWithoutOverflow(canvas: HTMLCanvasElement, popUpDiv: HTMLDivElement, requestedPercentage: { left: number, top: number }) {
  let top = requestedPercentage.top
  let left = requestedPercentage.left

  const canvasX = canvas.getBoundingClientRect().x
  const canvasWidth = canvas.getBoundingClientRect().width
  const hoverWidth = popUpDiv.getBoundingClientRect().width
  const maxX = canvasX + (canvasWidth * requestedPercentage.left) + hoverWidth + (canvasWidth * 0.01) + 20
  const verticalExceed = maxX - (document.documentElement.clientWidth || document.body.clientWidth)
  if (verticalExceed > 0) {
    left = (canvasWidth * left - verticalExceed) / canvasWidth
  }

  const canvasY = canvas.getBoundingClientRect().y
  const canvasHeight = canvas.getBoundingClientRect().height
  const hoverHeight = popUpDiv.getBoundingClientRect().height
  const maxY = canvasY + (canvasHeight * top) + hoverHeight + (canvasHeight * 0.01) + 20
  const horizontalExceed = maxY - (document.documentElement.clientHeight || document.body.clientHeight)
  if (horizontalExceed > 0) {
    top = (canvasHeight * top - horizontalExceed) / canvasHeight
  }

  return {
    top: top * 100 + 1 + '%',
    left: left * 100 + 1 + '%',
  }
}

function distance(pointA: { clientX: number, clientY: number }, pointB: { clientX: number, clientY: number }) {
  return Math.sqrt(Math.pow(pointB.clientX - pointA.clientX, 2) + Math.pow(pointB.clientY - pointA.clientY, 2))
}

function average(pointA: Point, pointB: Point): Point {
  return {
    x: (pointA.x + pointB.x) / 2,
    y: (pointA.y + pointB.y) / 2
  }
}
