export {};

declare global {
  export interface String {
    ellipsize(length: number): String

    removePrefix(prefix: string): String
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.ellipsize = function (length: number): String {
  if (this.length > length) {
    return this.substr(0, length) + "…"
  } else {
    return this
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.removePrefix = function (prefix: string): String {
  if (this.startsWith(prefix)) {
    return this.substr(prefix.length, this.length - prefix.length)
  } else {
    return this
  }
}
