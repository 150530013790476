import styles from './style.module.css'
import { ButtonHTMLAttributes, useState } from "react";

export type ButtonProps = { progress?: boolean, onMouseHold?: () => void } & ButtonHTMLAttributes<HTMLButtonElement>

export default function Button(props: ButtonProps) {
  const [onMouseHoldInterval, setOnMouseHoldInterval] = useState<NodeJS.Timeout>()
  const buttonProps = Object.assign({}, props);
  delete buttonProps.progress;
  delete buttonProps.onMouseHold;

  return (
    <button {...buttonProps}
            className={`${styles.root} ${props.className ?? ""}`}
            onMouseDown={props.onMouseHold ? (_) => {
              let interval = setInterval(_ => {
                props.onMouseHold?.()
              }, 1)
              setOnMouseHoldInterval(interval)
            } : props.onMouseDown}
            onMouseUp={props.onMouseHold ? (_) => {
              if (onMouseHoldInterval)
                clearInterval(onMouseHoldInterval)
            } : props.onMouseUp}
            onMouseLeave={props.onMouseHold ? (_) => {
              if (onMouseHoldInterval)
                clearInterval(onMouseHoldInterval)
            } : props.onMouseLeave}
            onTouchStart={props.onMouseHold ? (e) => {
              e.preventDefault()
              let interval = setInterval(_ => {
                props.onMouseHold?.()
              }, 1)
              setOnMouseHoldInterval(interval)
            } : props.onTouchStart}
            onTouchEnd={props.onMouseHold ? (e) => {
              e.preventDefault()
              if (onMouseHoldInterval)
                clearInterval(onMouseHoldInterval)
            } : props.onTouchEnd}
    >
      {props.progress !== true ? props.children : <div className={styles.loader} />}
    </button>
  );
}
