import { useEffect, useState } from 'react';

interface Size {
  width: number
  height: number
}

function getBoundingClientRect(element?: Element | null): Size {
  return {
    width: element?.getBoundingClientRect().width ?? 0,
    height: element?.getBoundingClientRect().height ?? 0,
  }
}

export default function useElementSize(element?: Element | null): Size {
  const [windowDimensions, setWindowDimensions] = useState(getBoundingClientRect(element));
  useEffect(() => {

    function setNewRect() {
      let newRect = getBoundingClientRect(element)
      setWindowDimensions(prevState => {
        if (newRect.width !== prevState.width
          || newRect.height !== prevState.height) {
          return newRect
        } else {
          return prevState
        }
      })
    }

    setNewRect()
    window.addEventListener('resize', setNewRect);
    window.addEventListener('scroll', setNewRect);

    return () => {
      window.removeEventListener('resize', setNewRect);
      window.removeEventListener('scroll', setNewRect);
    }

  }, [element]);

  return windowDimensions;
}
