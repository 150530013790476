import { Point } from "./Point";

export interface Rect {
  x: number
  y: number
  width: number
  height: number
}

export function rectContainsAnyPoint(rect: Rect, points: Point[]): boolean {
  return points
    .filter(point => point.x >= rect.x && point.x < rect.x + rect.width && point.y >= rect.y && point.y < rect.y + rect.height)
    .length > 0
}

export function rectIncludes(outerRect: Rect, innerRect: Rect): boolean {
  const oMinX = outerRect.x
  const oMaxX = outerRect.x + outerRect.width - 1
  const oMinY = outerRect.y
  const oMaxY = outerRect.y + outerRect.height - 1

  const iMinX = innerRect.x
  const iMaxX = innerRect.x + innerRect.width - 1
  const iMinY = innerRect.y
  const iMaxY = innerRect.y + innerRect.height - 1
  return oMinX <= iMinX && oMaxX >= iMaxX &&
    oMinY <= iMinY && oMaxY >= iMaxY
}

export function rectToPoints(rect: Rect): Point[] {
  const points: Point[] = []
  for (let x = rect.x; x < rect.x + rect.width; x++) {
    for (let y = rect.y; y < rect.y + rect.height; y++) {
      points.push({ x, y })
    }
  }
  return points
}
