import { useEffect, useState } from "react";
import { BigNumberish, ContractTransaction, ethers } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";
import { sleep } from "../utils/Promise";

export namespace Metamask {
  export type WaitStates = "waiting-user" | "waiting-chain" | "not-waiting"
  export type WaitStateCallback = (waitState: WaitStates) => void

  export enum ErrorCodes {
    REQUEST_ALREADY_WAITING = -32002,
    REQUEST_REJECTED = 4001,
  }

  export enum DataCodes {
    INSUFFICIENT_BALANCE = -32000,
  }

  export const CHAIN_PARAMS = {
    chainId: process.env.REACT_APP_CHAIN_ID!!,
    chainName: process.env.REACT_APP_CHAIN_NAME!!,
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: [process.env.REACT_APP_RPC_URL!!],
    blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORER_URL]
  }

  export async function switchChain() {
    if (!isMetamaskInstalled()) {
      return;
    }
    // noinspection HttpUrlsUsage
    if (CHAIN_PARAMS.rpcUrls[0].startsWith("http://")) {
      //It throws exception because of http url
      return
    }
    await (window as any).ethereum.request({ method: 'wallet_addEthereumChain', params: [CHAIN_PARAMS] });
  }

  export async function connectAccount() {
    if (!isMetamaskInstalled()) {
      return;
    }
    await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
  }

  export function isMetamaskInstalled(): boolean {
    return (window as any).ethereum !== undefined
  }

  export function formatEther(wei: BigNumberish): string {
    const result = ethers.utils.formatEther(wei);
    if (result.endsWith(".0")) {
      return result.substring(0, result.length - 2)
    } else {
      return result
    }
  }

  export function parseEther(ether: string): BigNumber {
    return ethers.utils.parseEther(ether)
  }

  export async function operationWrapper(operation: () => Promise<ContractTransaction>,
                                         waitStateCallback: WaitStateCallback,
                                         logType: string,
                                         extraWaitMs: number = 0,
                                         eventWait?: () => Promise<void>): Promise<Error | "CANCELLED" | "DONE"> {
    try {
      await (async () => {
        waitStateCallback("waiting-user")
        let transaction = await operation()
        waitStateCallback("waiting-chain")
        let eventWaitPromise = eventWait?.()
        await transaction.wait()
        if (eventWaitPromise) {
          await eventWaitPromise
        }
        if (extraWaitMs > 0) {
          await sleep(extraWaitMs)
        }
      })()
    } catch (reason: any) {
      waitStateCallback("not-waiting")
      if (reason?.data?.code === Metamask.DataCodes.INSUFFICIENT_BALANCE) {
        return Error("Insufficient balance")
      } else if (reason?.code === Metamask.ErrorCodes.REQUEST_REJECTED) {
        return "CANCELLED"
      } else {
        console.log(reason)
        return Error("An error occurred")
      }
    }
    waitStateCallback("not-waiting")
    return "DONE"
  }

  /**
   * @return
   *         id of selected chain
   *
   *         if null, no chain is selected
   *
   *         if undefined, chain is not retrieved yet
   */
  export function useChainId(): string | null | undefined {
    const [chainId, setChainId] = useState<string | null | undefined>()

    useEffect(() => {
      if (!Metamask.isMetamaskInstalled()) {
        setChainId(null)
        return
      }

      let listener = (chainId: string) => {
        setChainId(chainId)
      }

      (window as any).ethereum.request({ method: 'eth_chainId' }).then(listener);
      (window as any).ethereum.on('chainChanged', listener)

      return () => {
        (window as any).ethereum.removeListener('chainChanged', listener)
      }
    }, [setChainId])

    return chainId
  }

  /**
   * @return
   *         whether correct chain is selected
   *
   *         if undefined, chain is not retrieved yet
   */
  export function useCorrectChain(): boolean | undefined {
    const chainId = useChainId()
    if (chainId === undefined) {
      return undefined
    } else {
      return chainId?.toUpperCase() === Metamask.CHAIN_PARAMS.chainId.toUpperCase()
    }
  }

  /**
   * @return
   *         selected account
   *
   *         if null, no account is selected
   *
   *         if undefined, account is not retrieved yet
   */
  export function useAccount(correctChain: boolean): string | null | undefined {
    const [account, setAccount] = useState<string | null | undefined>()

    useEffect(() => {
      if (!Metamask.isMetamaskInstalled()) {
        setAccount(null)
        return
      }
      if (correctChain === undefined) {
        setAccount(undefined)
        return
      } else if (!correctChain) {
        setAccount(null)
        return
      }

      let listener = (accounts: string[]) => {
        if (accounts.length === 0) {
          setAccount(null)
        } else {
          setAccount(accounts[0])
        }
      }
      (window as any).ethereum.request({ method: 'eth_accounts' }).then(listener);
      (window as any).ethereum.on('accountsChanged', listener)

      return () => {
        (window as any).ethereum.removeListener('accountsChanged', listener)
      }
    }, [correctChain, setAccount])

    return account
  }
}
