import { Equality } from "../extensions/Array";

export interface Point {
  x: number
  y: number
}

export const pointEquality: Equality<Point> = (e1, e2) => {
  return e1.x === e2.x && e1.y === e2.y
}
