import NavigationAppIcon from '../NavigationAppIcon';
import NavigationItem from '../NavigationItem';
import styles from './style.module.css'
import useWindowDimensions from "../../hooks/UseWindowsDimentsions";
import { useState } from "react";
import { ReactComponent as MenuIcon } from './menuIcon.svg'

const items = [
  { text: "Home", target: "/" },
]

const MIN_WIDTH = 970

export default function Header() {
  const { width } = useWindowDimensions();
  const [collapsed, setCollapsed] = useState(true)

  return (
    <header className={styles.root}>
      <nav>

        <div className={styles.top_part}>
          <NavigationAppIcon />
          <div className={styles.right_menu_items}>
            {width > MIN_WIDTH ?
              <>
                {items.map(i => {
                  return <NavigationItem key={i.target} text={i.text} target={i.target} />
                })}
              </>
              :
              <>
                <MenuIcon className={styles.expand_collapse_icon} fill='var(--main-tint-color)' onClick={_ => {
                  setCollapsed(!collapsed)
                }} />
              </>
            }
          </div>
        </div>

        {width > MIN_WIDTH || collapsed ?
          <></>
          :
          <>
            <div className={styles.mobile_menu_items}>
              {items.map(i => {
                return <NavigationItem key={i.target} text={i.text} target={i.target} />
              })}
            </div>
          </>
        }
      </nav>
    </header>
  );
}
