import { Link } from "react-router-dom";
import logo from 'images/logo.png'
import styles from './style.module.css'
import React from "react";

export default function NavigationAppIcon() {
  return (
    <div className={styles.root}>
      <Link className={styles.logo} to='/'>
        <img alt='app' src={logo} />
        {(window.innerWidth <= 970) ? '' : '1st Rentable NFT Pixel Project on Blockchain!'}
      </Link>
    </div>

  );
}
