import { Metamask } from "../../metamask/Metamask";
import Button, { ButtonProps } from "../Button";
import React, { useState } from "react";
import FullScreenPopup from "../FullScreenPopup";
import { useAppContext } from "../../AppContext";

export default function MetamaskButton(props: ButtonProps) {
  const { correctChain, account } = useAppContext()
  const [installPopup, setInstallPopup] = useState<React.ReactNode>()
  const [chainPopup, setChainPopup] = useState<React.ReactNode>()
  const [accountPopup, setAccountPopup] = useState<React.ReactNode>()
  return (
    <>
      <Button {...props} onClick={_ => {
        if (!Metamask.isMetamaskInstalled()) {
          window.open('https://metamask.io/', '_blank', 'noopener')
          setInstallPopup("Please reload this page after installing Metamask")
        } else if (!correctChain) {
          setChainPopup("Please check Metamask plugin on your browser, and allow this site to switch network.")
          Metamask.switchChain()
            .then(_ => {
              setChainPopup(undefined)
            })
            .catch(reason => {
              if (reason.code === Metamask.ErrorCodes.REQUEST_ALREADY_WAITING) {
                //NO-OP
              } else {
                setChainPopup("An error occurred")
              }
            })
        } else if (!account) {
          setAccountPopup("Please check Metamask plugin on your browser, and connect an account.")
          Metamask.connectAccount()
            .then(_ => {
              setAccountPopup(undefined)
            })
            .catch(reason => {
              if (reason.code === Metamask.ErrorCodes.REQUEST_ALREADY_WAITING) {
                //NO-OP
              } else if (reason.code === Metamask.ErrorCodes.REQUEST_REJECTED) {
                setAccountPopup(undefined)
              } else {
                setAccountPopup("An error occurred")
              }
            })
        }
      }}>
        {Metamask.isMetamaskInstalled() ? correctChain ? account ? "" : "Connect Wallet" : "Switch to Avalanche" : "Install Metamask"}
      </Button>
      <FullScreenPopup isOpen={installPopup !== undefined}
                       onRequestClose={_ => setInstallPopup(undefined)}>
        {installPopup}
      </FullScreenPopup>
      <FullScreenPopup isOpen={chainPopup !== undefined && !correctChain}
                       onRequestClose={_ => setChainPopup(undefined)}>
        {chainPopup}
      </FullScreenPopup>
      <FullScreenPopup isOpen={accountPopup !== undefined && !account}
                       onRequestClose={_ => setAccountPopup(undefined)}>
        {accountPopup}
      </FullScreenPopup>
    </>
  );
}
