import { useState } from 'react';
import styles from './style.module.css'

export default function CountView(props: { count: number, max: number, min: number, onChange: (count: number) => void }) {
  const [count, setCount] = useState(props.count);

  return (
    <div className={[styles.root, "noselect"].join(' ')}>
      <div className={styles.negative} onClick={_ => {
        if (count === props.min) {
          return
        }
        setCount(count - 1)
        props.onChange(count - 1)
      }}>-
      </div>
      <div className={styles.count}>{count}</div>
      <div className={styles.positive} onClick={_ => {
        if (count === props.max) {
          return
        }
        setCount(count + 1)
        props.onChange(count + 1)
      }}>+
      </div>
    </div>
  );
}
