import styles from './style.module.css'
import Billboard from "../../components/Billboard";
import { useAppContext } from "../../AppContext";
import { BitPixels } from "../../metamask/contracts/BitPixels";
import { ethers } from "ethers";
import MetamaskButton from "../../components/MetamaskButton";
import Button from "../../components/Button";
import { useState } from "react";

export default function Home() {
  const { mapInfo, player, modifiablePixelGroups, supply, balance, account } = useAppContext()
  const [progress, setProgress] = useState(false)
  return (
    <div className={styles.root}>
      {account ? balance === 0 && supply !== 4 ?
          <Button
            progress={progress}
            onClick={_ => {
              setProgress(true)
              BitPixels.enter().finally(() => setProgress(false))
            }}>Enter</Button>
          :
          supply === 4 ? "Full" : <></>
        : <MetamaskButton />
      }
      <Billboard
        className={styles.billboard}
        mapInfo={mapInfo}
        player={player}
        modifiablePixelGroups={modifiablePixelGroups}
      />
      <br />
    </div>
  );
}
