import styles from './style.module.css'
import React, { ReactNode, Ref, useImperativeHandle, useMemo, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Point } from "../../utils/Point";
import { Rect, rectToPoints } from "../../utils/Rect";
import Button from "../Button";
import { omittedCopy } from "../../utils/GeneralUtil";
import { useAppContext } from "../../AppContext";
import { BitPixels, PixelInfo } from "../../metamask/contracts/BitPixels";
import CountView from "../CountView";

export interface BillboardSelectionPopupRef {
  root: HTMLDivElement | null
}

export type BillboardSelectionPopupContentProps = { selection_start: Point, selected: Rect }

export type BillboardSelectionPopupProps =
  { onClose: () => void }
  & BillboardSelectionPopupContentProps
  & React.HTMLAttributes<HTMLDivElement>

export type ID_POWER_MAP = { [pixelId: string]: number }

function getPowersFrom(mapped: PixelInfo[]): ID_POWER_MAP {
  let r: ID_POWER_MAP = {}
  for (let pixelInfo of mapped) {
    r[pixelInfo.id] = pixelInfo.power
  }
  return r
}

function BillboardSelectionPopup(props: BillboardSelectionPopupProps, ref: Ref<BillboardSelectionPopupRef>) {
  const { mapInfo, player, refresh, modifiablePixelGroups } = useAppContext()
  const [unused, setUnused] = useState(0)
  const [progress, setProgress] = useState(false)
  const rootRef = useRef(null)
  const area = props.selected.width * props.selected.height

  useImperativeHandle(ref, () => ({
    get root() {
      return rootRef.current;
    }
  }), [rootRef]);

  const mapped = useMemo(() => {
    return rectToPoints(props.selected).map(value => mapInfo.pixels[value.x][value.y])
  }, [props.selected, mapInfo])

  let content: ReactNode
  let selectedGroup = modifiablePixelGroups.filter(value => value.containsAll(mapped))?.[0]
  const [powers, setPowers] = useState<ID_POWER_MAP>(getPowersFrom(mapped))
  if (selectedGroup && selectedGroup.length === mapped.length) {
    content = <>
      <span>Unused: {unused}</span>
      <table>
        {Array.from(Array(props.selected.height).keys()).map(y => {
          return <tr>
            {
              Array.from(Array(props.selected.width).keys()).map(x => {
                let cell = mapInfo.pixels[props.selected.x + x][props.selected.y + y]
                return <td><CountView
                  key={cell.id}
                  count={powers[cell.id] ?? 0} max={(powers[cell.id] ?? 0) + unused} min={0}
                  onChange={count => {
                    setPowers(prevState => {
                      let neww = { ...prevState }
                      let oldValue = prevState[cell.id] ?? 0
                      setUnused(prevState1 => prevState1 - count + oldValue)
                      neww[cell.id] = count
                      return neww
                    })
                  }} /></td>
              })
            }
          </tr>
        })}
      </table>
      <Button
        progress={progress}
        onClick={_ => {
          if (unused > 0) {
            return
          }
          setProgress(true)
          BitPixels.setPowers(powers)
            .then(_ => {
              setProgress(false)
              props.onClose();
            })
            .catch(_ => setProgress(false))
        }}>Modify</Button>
    </>
  } else if (props.selected.width * props.selected.height !== 2) {
    content = "Please select 2 pixels"
  } else if (mapped.filter(value => value.owner === player).length === 0) {
    content = "Please select your pixel"
  } else if (mapped.filter(value => value.owner !== player).length === 0) {
    content = "Please select enemy pixel"
  } else if (mapped.some(value => value.maxTimeMs > Date.now())) {
    content = "One of the pixels are on cooldown."
  } else {
    content = <Button
      progress={progress}
      onClick={_ => {
        setProgress(true)
        BitPixels.attack(mapped, player)
          .then(_ => {
            setProgress(false)
            props.onClose();
          })
          .catch(_ => setProgress(false))
      }}> WAR!</Button>
  }

  return (
    <div {...omittedCopy(props, ["onClose"])} className={`${props.className ?? ''} ${styles.root}`} ref={rootRef}>
      <div className={styles.close_button_div}>
        <IconButton size="small" onClick={_ => {
          props.onClose()
        }}>
          <Close fontSize="small" />
        </IconButton>
      </div>
      <span className={styles.span_selected}>{area} {area !== 1 ? 'pixels' : 'pixel'} selected</span>
      {content}
    </div>
  );
}

export default React.forwardRef(BillboardSelectionPopup)
