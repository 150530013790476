export {};
declare global {
  // noinspection JSUnusedGlobalSymbols
  export interface Number {
    padZero(length: number): string;
  }
}
// eslint-disable-next-line no-extend-native
Number.prototype.padZero = function (number: number): string {
  const zero = number - this.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + this;
}
