import styles from './style.module.css'
import React, { Ref, useImperativeHandle, useRef } from "react";
import { Point } from "../../utils/Point";
import HoverHomeContent from "./HoverHomeContent";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export interface BillboardHoverPopupRef {
  root: HTMLDivElement | null
}

export type BillboardHoverPopupProps =
  { hovered: Point, showClose: boolean, onClose?: () => void }
  & React.HTMLAttributes<HTMLDivElement>

function BillboardHoverPopup(props: BillboardHoverPopupProps, ref: Ref<BillboardHoverPopupRef>) {
  const rootRef = useRef(null)
  useImperativeHandle(ref, () => ({
    get root() {
      return rootRef.current;
    }
  }), [rootRef]);

  const divProps: React.HTMLAttributes<HTMLDivElement> = Object.assign({}, props);
  // @ts-ignore
  delete divProps.showClose;
  // @ts-ignore
  delete divProps.hovered;
  // @ts-ignore
  delete divProps.onClose;

  return (
    <div {...divProps} className={`${props.className ?? ''} ${styles.root}`} ref={rootRef}>
      {props.showClose ? <div className={styles.close_button_div}>
        <IconButton size="small" onClick={_ => {
          props.onClose?.()
        }}>
          <Close fontSize="small" />
        </IconButton>
      </div> : <></>}
      <span className={styles.coordinate}>({props.hovered.x},{props.hovered.y})</span>
      <HoverHomeContent {...props} />
    </div>
  );
}

export default React.forwardRef(BillboardHoverPopup)
