export function extractTypeFromBase64Header(data: string) {
  return data.substring(data.indexOf('/') + 1, data.indexOf(';'))
}

export function extractHeaderFromBase64(data: string) {
  return data.substring(0, data.indexOf('base64,') + 7)
}

export function getRawBase64(data: string) {
  if (data.startsWith('data:image')) {
    return data.substring(data.indexOf('base64,') + 7)
  } else {
    return data
  }
}

export function omittedCopy<T, K extends (keyof T)[]>(object: T, properties: K): Omit<T, typeof properties[0]> {
  if (properties.length === 0) {
    return object
  }
  const clone = Object.assign({}, object);
  for (let property of properties) {
    delete clone[property]
  }
  return clone
}

export function msToRemainingTime(ms: number): string {
  let remainingMs = ms - Date.now()
  let minute = Math.floor(remainingMs / (60 * 1000))
  remainingMs = remainingMs - (minute * 60 * 1000)
  let second = Math.floor(remainingMs / 1000)
  return `${minute}:${second.padZero(2)}`
}
